import { mColors } from "@minuthq/meatball-ui-react"

import { ReportCard } from "src/components/Reports/ReportCard"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import NoiseMonitoringIcon from "src/ui/icons/noise-monitoring.svg"
import { MBadge } from "src/ui/MBadge/MBadge"

type ReportsCardProps = {
  reportsAvailable: boolean
}
export function NoiseSummaryReportCard({ reportsAvailable }: ReportsCardProps) {
  const { t, langKeys } = useTranslate()

  const titleComponents = [
    <div key={t(langKeys.new_badge_title)}>
      <MBadge color="promotional">{t(langKeys.new_badge_title)}</MBadge>
    </div>,
  ]

  if (!reportsAvailable) return null

  return (
    <ReportCard
      icon={
        <NoiseMonitoringIcon
          width={24}
          height={24}
          color={mColors.textPrimary}
        />
      }
      titleComponents={titleComponents}
      title={t(langKeys.noise_report_title)}
      linkTo={Routes.NoiseInsights.location().pathname}
      description={t(langKeys.noise_report_pro_body)}
    />
  )
}
