import { mColors } from "@minuthq/meatball-ui-react"

import { ReportCard } from "src/components/Reports/ReportCard"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import MoldIcon from "src/ui/icons/mold.svg"

export function MoldInsightsReportCard() {
  const { t, langKeys } = useTranslate()

  return (
    <ReportCard
      icon={<MoldIcon width={24} height={24} color={mColors.textPrimary} />}
      title={t(langKeys.mold_risk_analytics)}
      description={t(langKeys.mold_analytics_card_description)}
      linkTo={Routes.MoldInsights.location().pathname}
    />
  )
}
